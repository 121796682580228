import { useSwiper } from 'swiper/react';

export interface SwipperButtonProps {
  className: string;
}

export const SwiperButtonNext = (prop: SwipperButtonProps) => {
  const swiper = useSwiper();
  return (
    <button
      aria-label="Next button"
      onClick={() => swiper.slideNext()}
      className={[
        'bg-white -mr-2 lg:-mr-4 flex justify-center items-center w-[56px] h-[56px] rounded-full shadow focus:outline-none border border-black',
        prop.className
      ].join(' ')}>
      <svg width="32" height="30" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 23.9812L22 14.9994L12 6.01758" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </button>
  );
};
