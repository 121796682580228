import React, { useEffect, useState } from 'react';
import APIService from '../../../services/services';
import { getStateName } from '../../../helpers/functions';
import { DOUBLE_ARROW_DOWN, DOUBLE_ARROW_UP } from '../../../helpers/svgIcons';

const itemToShow = 5;

function State({ destination, city, interest, state, language, metroRegion, zip, supplier, callback }: any) {
  const stateType = 'FacetedStates';
  const metroType = 'FacetedMetroRegions';
  const cityType = 'FacetedCities';
  const [data, setData] = useState<any[]>([]);
  const [metroData, setMetroData] = useState<any[]>([]);
  const [cityData, setCityData] = useState<any[]>([]);
  const [showFull, setShowFull] = useState(false);
  const [type, setType] = useState('state');
  const [loading, setLoading] = useState<boolean>(true);
  const [showAll, setShowAll] = useState(true);
  const fetch = () => {
    setLoading(true);
    APIService()
      .gtcService.fetchFaceted({
        destination: destination || '',
        agencyCity: city || '',
        interest: interest || '',
        state: state || '',
        language: language || '',
        metroRegion: metroRegion || '',
        zip: zip || '',
        supplier: supplier || '',
        type: stateType,
        radius: ''
      })
      .then((response) => {
        setLoading(false);
        if (response.responseStatus === 1) {
          setData(response.data);
        } else {
          setData([]);
        }
      });
  };

  const fetchMetros = () => {
    setLoading(true);
    APIService()
      .gtcService.fetchFaceted({
        destination: destination || '',
        agencyCity: city || '',
        interest: interest || '',
        state: state || '',
        language: language || '',
        metroRegion: metroRegion || '',
        zip: zip || '',
        supplier: supplier || '',
        type: metroType,
        radius: ''
      })
      .then((response) => {
        setLoading(false);
        setLoading(false);
        if (response.responseStatus === 1) {
          setMetroData(response.data);
        } else {
          setMetroData([]);
        }
      });
  };

  const fetchCities = () => {
    setLoading(true);
    APIService()
      .gtcService.fetchFaceted({
        destination: destination || '',
        agencyCity: city || '',
        interest: interest || '',
        state: state || '',
        language: language || '',
        metroRegion: metroRegion || '',
        zip: zip || '',
        supplier: supplier || '',
        type: cityType,
        radius: ''
      })
      .then((response) => {
        if (response.responseStatus === 1) {
          setCityData(response.data);
        } else {
          setCityData([]);
        }
      });
  };

  useEffect(() => {
    fetch();
    if (state && state !== '') {
      if (metroRegion !== '') {
        setType('city');
      } else {
        setType('metro');
      }
    } else {
      setType('state');
    }
  }, [destination, interest, state, language, supplier, metroRegion]);

  // useEffect(() => {
  //   if (zip && zip !== '') {
  //     trigger();
  //   }
  // }, [zip]);

  useEffect(() => {
    if (state && state !== '') {
      fetchMetros();
      if (metroRegion !== '') {
        setType('city');
      } else {
        setType('metro');
      }
    }
  }, [state, metroRegion]);

  useEffect(() => {
    if (metroRegion !== '') {
      fetchCities();
      setType('city');
    }
  }, [metroRegion]);

  useEffect(() => {
    if (city !== '') {
      setType('city');
    }
  }, [city]);

  return (
    <>
      <div>
        <div className="my-4 flex flex-row justify-between items-center">
          <div className=" text-31-2">ADVISOR LOCATION</div>
          <div className="cursor-pointer" onClick={() => setShowAll(!showAll)}>
            {showAll ? <DOUBLE_ARROW_UP /> : <DOUBLE_ARROW_DOWN />}
          </div>
        </div>
        {loading ? (
          <div className="flex animate-pulse flex-row  h-full justify-center">
            <div className=" bg-gtc4 h-44 w-full mr-4 mb-2"></div>
          </div>
        ) : (
          <div className={`${showAll ? 'max-h-[5000px]' : 'max-h-0'} transition-all delay-150 duration-300 overflow-hidden`}>
            {data.length > 0 ? (
              <div className="text-4xl flex flex-col">
                {type === 'state' && (
                  <div className="flex flex-col items-start ">
                    <div className="flex flex-col gap-2.5 items-start ">
                      {data.slice(0, itemToShow).map((x) => (
                        <div
                          key={x.state}
                          onClick={() => callback('state', x.state)}
                          className="text-base  cursor-pointer  text-gtc1 hover:text-gray-800"
                          role="button"
                          tabIndex={0}>
                          {getStateName(x.state)}
                        </div>
                      ))}
                      <div
                        className={`${
                          showFull ? 'max-h-[500px]' : 'max-h-0'
                        } transition-all delay-150 duration-300 overflow-hidden w-full flex flex-col gap-2.5 `}>
                        {data.slice(itemToShow, data.length).map((x) => (
                          <div
                            key={x.state}
                            onClick={() => callback('state', x.state)}
                            className="text-base  cursor-pointer  text-gtc1 hover:text-gray-800"
                            role="button"
                            tabIndex={0}>
                            {getStateName(x.state)}
                          </div>
                        ))}
                      </div>
                    </div>

                    {data.length > itemToShow && (
                      <div
                        className="text-20-8 w-full  cursor-pointer font-[350]  text-black  mt-4  "
                        onClick={() => setShowFull(!showFull)}>
                        {!showFull ? (
                          <div className="flex flex-row gap-1 items-center justify-between">
                            {/* <IoPlaySharp /> */}
                            More Locations
                            {` (${data.length - itemToShow})`}
                            <DOUBLE_ARROW_DOWN />
                          </div>
                        ) : (
                          <div className="flex flex-row gap-1 items-center  justify-between">
                            Close
                            <DOUBLE_ARROW_UP />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}

                {type === 'metro' && (
                  <div className="flex flex-col items-start ">
                    <div onClick={() => callback('state', 0)} className="text-base  cursor-pointer  text-gtc1 hover:text-gray-500">
                      All States
                    </div>
                    <div className="text-base   text-gtc1 font-semibold">State</div>
                    <div className="text-base   text-gray-500">{getStateName(state)}</div>

                    <div className="flex flex-col items-start pl-4">
                      <div className="text-base   text-gtc1 font-semibold">Metro Region</div>
                      {metroData.map((x) => (
                        <div
                          key={x.metroRegion}
                          onClick={() => callback('metro', x.metroRegion)}
                          className={[
                            'text-base  cursor-pointer font-normal',
                            x.metroRegion === metroRegion ? 'text-gray-500' : ' text-gtc1 hover:text-gray-500'
                          ].join(' ')}
                          role="button"
                          tabIndex={0}>
                          {x.metroRegion}
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {type === 'city' && (
                  <div className="flex flex-col items-start ">
                    <div onClick={() => callback('state', 0)} className="text-base  cursor-pointer  text-gtc1 hover:text-gray-500">
                      All States
                    </div>
                    <div className="text-base   text-gtc1 font-semibold">State</div>
                    <div onClick={() => callback('state', state)} className="text-base  cursor-pointer  text-gtc1 hover:text-gray-500">
                      {getStateName(state)}
                    </div>
                    <div className="pl-4">
                      <div className="text-base   text-gtc1 font-semibold">Metro Region</div>
                      <div
                        onClick={() => (city !== '' ? callback('metro', metroRegion) : {})}
                        className={['text-base   text-gtc1 hover:text-gray-500', city !== '' ? 'cursor-pointer' : 'text-gray-500'].join(
                          ' '
                        )}>
                        {metroRegion}
                      </div>

                      <div className="flex flex-col items-start pl-4">
                        <div className="text-base   text-gtc1 font-semibold">City</div>

                        {cityData.map((x) => (
                          <div
                            key={x.city}
                            onClick={() => (x.city !== city ? callback('city', x.city) : {})}
                            className={[
                              'text-base   font-normal',
                              x.city === city ? 'text-gray-500' : ' text-gtc1 hover:text-gray-500 cursor-pointer'
                            ].join(' ')}
                            role="button"
                            tabIndex={0}>
                            {x.city}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="text-base">Please broaden your search criteria</div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default State;
