import { useState, useEffect } from 'react';
import { StoryItemViewModel } from '../../helpers/types';
import APIService from '../../services/services';
import { Helmet } from 'react-helmet';
import { Hero } from '../../components/Hero';
import MainWrapper from '../../components/MainWrapper';
import { clipString } from '../../helpers/functions';

const Limit = 4;
function AdvisorInspiration() {
  const [stories, setStories] = useState<StoryItemViewModel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [titleSize, setTitleSize] = useState(30);
  const [descSize, setDescSize] = useState(68);
  const [showAll, setShowAll] = useState<boolean>(false);

  const getStories = () => {
    setLoading(true);
    APIService()
      .gtcService.fetchStories()
      .then((res) => {
        setLoading(false);
        if (res.responseStatus === 1) {
          setStories(res.data);
        } else {
          setStories([]);
        }
      });
  };

  useEffect(() => {
    // Function to update value based on screen width
    const updateValue = () => {
      if (window.innerWidth < 600) {
        setTitleSize(50);
        setDescSize(100);
      } else if (window.innerWidth < 1200) {
        setTitleSize(25);
        setDescSize(68);
      } else {
        setTitleSize(25);
        setDescSize(68);
      }
    };

    // Initial check
    updateValue();

    // Update value on window resize
    window.addEventListener('resize', updateValue);
    return () => window.removeEventListener('resize', updateValue); // Cleanup listener on unmount
  }, []);

  useEffect(() => {
    getStories();
  }, []);

  return (
    <>
      <Helmet>
        <meta name="title" content="Luxury Travel Experience Inspiration and Ideas | Global Travel Collection" />
        <meta
          name="description"
          content="Global Travel Collection’s stories bring you a world without borders. See ideas and inspriations from luxury expeirences around the world brought to you by agents and travelers."
        />
        <title>Luxury Travel Experience Inspiration and Ideas | Global Travel Collection</title>
        <link rel="canonical" href={window.location.href}></link>
      </Helmet>
      <Hero title="TRAVEL JOURNAL" img="/images/new-hero/journal.png" />

      <MainWrapper>
        <div className="flex flex-col  justify-center items-center my-10">
          <div className="text-31-2">LET'S GO SOMEWHERE</div>
          <div className="text-20-8 text-gtc1 text-center md:text-left">
            An esteemed journal featuring adventures and experiences, carefully curated by our expert advisors.
          </div>
        </div>

        {loading ? (
          <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
            <div className="loader ease-linear rounded-full border-4 border-t-4  h-12 w-12 mb-4"></div>
            <h2 className="text-center text-white text-xl font-semibold">Searching...</h2>
          </div>
        ) : (
          <section className="-mx-4 md:mx-0 mb-10">
            <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 w-full">
              {stories.slice(0, Limit).map((x) => (
                <li key={x.id}>
                  <div className="relative shadow-md hover:opacity-70">
                    <a title={x.title} href={x.url}>
                      <img className=" object-cover h-[288px] w-full" src={x.thumbnailImage} alt={x.title} width={282} height={288} />

                      <div className=" bg-white min-h-[190px]  w-full py-4 flex flex-col  items-center gap-2 px-1">
                        <div className="text-31-2 text-center">{clipString(x.title, titleSize)}</div>
                        <div className="text-20-8 text-gtc1 text-center md:text-left px-1">{clipString(x.shortDescription, descSize)}</div>
                      </div>
                    </a>
                  </div>
                </li>
              ))}
            </ul>

            <div className={`${showAll ? 'max-h-[50000px]' : 'max-h-0'} transition-all delay-150 duration-300 overflow-hidden w-full`}>
              <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-10 w-full mt-8 mb-10">
                {stories.slice(Limit, stories.length).map((x) => (
                  <li key={x.id}>
                    <div className="relative shadow-md hover:opacity-70">
                      <a title={x.title} href={x.url}>
                        <img className=" object-cover h-[288px] w-full" src={x.thumbnailImage} alt={x.title} width={282} height={288} />

                        <div className=" bg-white min-h-[190px]   w-full py-4 flex flex-col items-center gap-2 px-1">
                          <div className="text-31-2 text-center">{clipString(x.title, titleSize)}</div>
                          <div className="text-20-8 text-gtc1 text-center md:text-left px-1">
                            {clipString(x.shortDescription, descSize)}
                          </div>
                        </div>
                      </a>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </section>
        )}

        {stories.length > Limit && !loading && (
          <div className="flex justify-center mb-10">
            <button className="dark-btn" onClick={() => setShowAll(!showAll)}>
              {showAll ? 'View Less' : 'View More'}
            </button>
          </div>
        )}
      </MainWrapper>
    </>
  );
}

export default AdvisorInspiration;
