//@ts-nocheck
import React from 'react';

export const AirplaneIcon: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="40" cy="40.5" r="38.75" stroke="#606062" strokeWidth="2.5" />
    <path
      d="M52.0833 55.5L48.3333 38.4167L55.625 31.125C58.75 28 59.7917 23.8333 58.75 21.75C56.6667 20.7083 52.5 21.75 49.375 24.875L42.0833 32.1667L25 28.4167C23.9583 28.2083 23.125 28.625 22.7083 29.4583L22.0833 30.5C21.6667 31.5417 21.875 32.5833 22.7083 33.2083L33.75 40.5L29.5833 46.75H23.3333L21.25 48.8333L27.5 53L31.6667 59.25L33.75 57.1667V50.9167L40 46.75L47.2917 57.7917C47.9167 58.625 48.9583 58.8333 50 58.4167L51.0417 58C51.875 57.375 52.2917 56.5417 52.0833 55.5Z"
      stroke="#606062"
      strokeWidth="3.12"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const BriefCaseIcon: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="40" cy="40" r="38.75" stroke="#606062" strokeWidth="2.5" />
    <path
      d="M56.7235 28.6562H22.4224C21.5613 28.6562 20.8633 29.3543 20.8633 30.2154V55.1616C20.8633 56.0227 21.5613 56.7208 22.4224 56.7208H56.7235C57.5846 56.7208 58.2826 56.0227 58.2826 55.1616V30.2154C58.2826 29.3543 57.5846 28.6562 56.7235 28.6562Z"
      stroke="#606062"
      strokeWidth="3.11828"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.3687 28.6545V25.5362C47.3687 24.7092 47.0402 23.9161 46.4554 23.3313C45.8706 22.7465 45.0775 22.418 44.2505 22.418H34.8956C34.0686 22.418 33.2755 22.7465 32.6907 23.3313C32.1059 23.9161 31.7773 24.7092 31.7773 25.5362V28.6545"
      stroke="#606062"
      strokeWidth="3.11828"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.2826 39.2383C52.5962 42.5269 46.1419 44.2548 39.573 44.247C33.0028 44.2641 26.5461 42.5356 20.8633 39.2383"
      stroke="#606062"
      strokeWidth="3.11828"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M37.2344 38.0098H41.9118" stroke="#606062" strokeWidth="3.11828" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const MicIcon: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="40" cy="40" r="38.75" stroke="#606062" strokeWidth="2.5" />
    <path
      d="M47.7181 27.2712C47.7181 22.7538 44.0561 19.0918 39.5387 19.0918C35.0214 19.0918 31.3594 22.7538 31.3594 27.2712V39.5402C31.3594 44.0575 35.0214 47.7196 39.5387 47.7196C44.0561 47.7196 47.7181 44.0575 47.7181 39.5402V27.2712Z"
      stroke="#606062"
      strokeWidth="3.06726"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.2266 36.472V39.5393C25.2266 47.4446 31.6351 53.8532 39.5404 53.8532C47.4458 53.8532 53.8543 47.4446 53.8543 39.5393V36.472M39.5404 20.1133V26.2478M31.3611 31.3599H36.4732M42.6077 31.3599H47.7198M31.3611 37.4944H36.4732M42.6077 37.4944H47.7198"
      stroke="#606062"
      strokeWidth="3.06726"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M39.5391 53.8555V59.99" stroke="#606062" strokeWidth="4.08968" />
    <path d="M31.3594 59.9883H47.7181" stroke="#606062" strokeWidth="3.06726" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
export const GTCSIMPLEICON: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg width="70" height="71" viewBox="0 0 70 71" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35.0621 0.910211C26.7508 0.895468 18.7055 3.83888 12.366 9.21368C6.02656 14.5885 1.80666 22.0438 0.461513 30.2456C-0.883633 38.4473 0.733799 46.86 5.02434 53.9782C9.31487 61.0964 15.9985 66.4555 23.8791 69.0964C31.7596 71.7372 40.3228 71.4875 48.036 68.3919C55.7493 65.2962 62.1093 59.5568 65.9777 52.2006C69.8461 44.8445 70.9705 36.3518 69.1497 28.2424C67.3289 20.133 62.6817 12.9362 56.0397 7.94001L54.8479 10.1933C58.7648 13.2247 61.9357 17.1126 64.1177 21.5589C66.2997 26.0053 67.4349 30.8921 67.4363 35.845C67.4233 42.7357 65.2082 49.4417 61.1144 54.9844C57.0206 60.5271 51.2624 64.6163 44.6803 66.6551C39.8355 68.1569 34.7054 68.4977 29.7045 67.6498C24.7037 66.8019 19.9725 64.7892 15.8935 61.7743C11.8146 58.7595 8.50222 54.8272 6.22429 50.2952C3.94637 45.7633 2.76682 40.7589 2.78096 35.6868C2.77036 30.5187 4.00359 25.424 6.37651 20.8329C8.74944 16.2418 12.1924 12.2892 16.4145 9.30887C20.6366 6.32853 25.514 4.40801 30.6346 3.70952C35.7553 3.01102 40.9688 3.55506 45.8349 5.29569L47.0267 3.04243C43.1937 1.63812 39.1442 0.916455 35.0621 0.910211Z"
      fill="white"
    />
    <path
      d="M35.0074 5.91016C29.9695 5.90756 25.0179 7.24072 20.6406 9.77823C16.2633 12.3157 12.6114 15.97 10.0447 20.3809C7.47812 24.7918 6.08538 29.8071 6.0038 34.9324C5.92222 40.0578 7.15461 45.1163 9.57951 49.6095C12.0044 54.1026 15.5381 57.8753 19.8323 60.5557C24.1266 63.2362 29.0332 64.7318 34.0685 64.8952C39.1038 65.0586 44.094 63.8841 48.5474 61.4876C53.0008 59.091 56.7636 55.5551 59.465 51.2283L57.0388 49.9845C53.4393 55.7255 47.8766 59.9057 41.4251 61.7176C34.9737 63.5295 28.0913 62.8447 22.1071 59.7953C16.1229 56.7459 11.4616 51.5483 9.02341 45.2065C6.58525 38.8646 6.54323 31.8285 8.90548 25.457C11.2677 19.0854 15.8667 13.8306 21.814 10.7075C27.7613 7.58436 34.6351 6.81445 41.1077 8.54648C47.5803 10.2785 53.1925 14.3896 56.8603 20.0857C60.5281 25.7818 61.9913 32.6588 60.9673 39.3884L63.5148 40.6892C63.8332 38.9609 63.9956 37.2066 64 35.4481C63.9926 27.6266 60.9356 20.1275 55.5001 14.5968C50.0645 9.06612 42.6944 5.95567 35.0074 5.94813"
      fill="white"
    />
  </svg>
);

export const MAPMARKERICON: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 17.9102C18.2091 17.9102 20 16.1193 20 13.9102C20 11.701 18.2091 9.91016 16 9.91016C13.7909 9.91016 12 11.701 12 13.9102C12 16.1193 13.7909 17.9102 16 17.9102Z"
      stroke="#606062"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26 13.9102C26 22.9102 16 29.9102 16 29.9102C16 29.9102 6 22.9102 6 13.9102C6 11.258 7.05357 8.71445 8.92893 6.83909C10.8043 4.96372 13.3478 3.91016 16 3.91016C18.6522 3.91016 21.1957 4.96372 23.0711 6.83909C24.9464 8.71445 26 11.258 26 13.9102V13.9102Z"
      stroke="#606062"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DOUBLEARROWUPICON: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 26L16 16L26 26" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 16L16 6L26 16" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const SEARCH_ICON: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 15L21 22" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M8.90007 16.8001C13.2632 16.8001 16.8001 13.2632 16.8001 8.90007C16.8001 4.53698 13.2632 1 8.90007 1C4.53698 1 1 4.53698 1 8.90007C1 13.2632 4.53698 16.8001 8.90007 16.8001Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CANCEL_ICON: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="13" cy="12.5" r="12.5" fill="#38373D" />
    <path
      d="M18.3388 17.0606C18.3899 17.1117 18.4305 17.1724 18.4581 17.2391C18.4858 17.3059 18.5 17.3775 18.5 17.4497C18.5 17.522 18.4858 17.5935 18.4581 17.6603C18.4305 17.7271 18.3899 17.7877 18.3388 17.8388C18.2877 17.8899 18.2271 17.9305 18.1603 17.9581C18.0935 17.9858 18.022 18 17.9497 18C17.8775 18 17.8059 17.9858 17.7391 17.9581C17.6724 17.9305 17.6117 17.8899 17.5606 17.8388L13 13.2775L8.43938 17.8388C8.33618 17.942 8.19622 18 8.05028 18C7.90433 18 7.76437 17.942 7.66117 17.8388C7.55798 17.7356 7.5 17.5957 7.5 17.4497C7.5 17.3038 7.55798 17.1638 7.66117 17.0606L12.2225 12.5L7.66117 7.93938C7.55798 7.83618 7.5 7.69622 7.5 7.55028C7.5 7.40433 7.55798 7.26437 7.66117 7.16117C7.76437 7.05798 7.90433 7 8.05028 7C8.19622 7 8.33618 7.05798 8.43938 7.16117L13 11.7225L17.5606 7.16117C17.6638 7.05798 17.8038 7 17.9497 7C18.0957 7 18.2356 7.05798 18.3388 7.16117C18.442 7.26437 18.5 7.40433 18.5 7.55028C18.5 7.69622 18.442 7.83618 18.3388 7.93938L13.7775 12.5L18.3388 17.0606Z"
      fill="white"
    />
  </svg>
);
export const DOUBLE_ARROW_UP: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.875 21.625L13 13.5L21.125 21.625" stroke="black" strokeWidth="1.625" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.875 13.5L13 5.375L21.125 13.5" stroke="black" strokeWidth="1.625" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const DOUBLE_ARROW_DOWN: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.125 4.875L13 13L4.875 4.875" stroke="black" strokeWidth="1.625" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M21.125 13L13 21.125L4.875 13" stroke="black" strokeWidth="1.625" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const FILTER_ICON: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 8.85938V17.7188" stroke="white" strokeWidth="1.3125" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11 3.28125V5.57812" stroke="white" strokeWidth="1.3125" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M11 8.85938C11.9061 8.85938 12.6406 8.12484 12.6406 7.21875C12.6406 6.31266 11.9061 5.57812 11 5.57812C10.0939 5.57812 9.35938 6.31266 9.35938 7.21875C9.35938 8.12484 10.0939 8.85938 11 8.85938Z"
      stroke="white"
      strokeWidth="1.3125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M16.9062 15.4219V17.7188" stroke="white" strokeWidth="1.3125" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16.9062 3.28125V12.1406" stroke="white" strokeWidth="1.3125" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M16.9062 15.4219C17.8123 15.4219 18.5469 14.6873 18.5469 13.7812C18.5469 12.8752 17.8123 12.1406 16.9062 12.1406C16.0002 12.1406 15.2656 12.8752 15.2656 13.7812C15.2656 14.6873 16.0002 15.4219 16.9062 15.4219Z"
      stroke="white"
      strokeWidth="1.3125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M5.09375 12.7969V17.7188" stroke="white" strokeWidth="1.3125" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.09375 3.28125V9.51562" stroke="white" strokeWidth="1.3125" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M5.09375 12.7969C5.99984 12.7969 6.73438 12.0623 6.73438 11.1562C6.73438 10.2502 5.99984 9.51562 5.09375 9.51562C4.18766 9.51562 3.45312 10.2502 3.45312 11.1562C3.45312 12.0623 4.18766 12.7969 5.09375 12.7969Z"
      stroke="white"
      strokeWidth="1.3125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const FILTER_ICON_BLACK: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 8.85938V17.7188" stroke="black" strokeWidth="1.3125" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11 3.28125V5.57812" stroke="black" strokeWidth="1.3125" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M11 8.85938C11.9061 8.85938 12.6406 8.12484 12.6406 7.21875C12.6406 6.31266 11.9061 5.57812 11 5.57812C10.0939 5.57812 9.35938 6.31266 9.35938 7.21875C9.35938 8.12484 10.0939 8.85938 11 8.85938Z"
      stroke="black"
      strokeWidth="1.3125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M16.9062 15.4219V17.7188" stroke="black" strokeWidth="1.3125" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16.9062 3.28125V12.1406" stroke="black" strokeWidth="1.3125" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M16.9062 15.4219C17.8123 15.4219 18.5469 14.6873 18.5469 13.7812C18.5469 12.8752 17.8123 12.1406 16.9062 12.1406C16.0002 12.1406 15.2656 12.8752 15.2656 13.7812C15.2656 14.6873 16.0002 15.4219 16.9062 15.4219Z"
      stroke="black"
      strokeWidth="1.3125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M5.09375 12.7969V17.7188" stroke="black" strokeWidth="1.3125" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.09375 3.28125V9.51562" stroke="black" strokeWidth="1.3125" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M5.09375 12.7969C5.99984 12.7969 6.73438 12.0623 6.73438 11.1562C6.73438 10.2502 5.99984 9.51562 5.09375 9.51562C4.18766 9.51562 3.45312 10.2502 3.45312 11.1562C3.45312 12.0623 4.18766 12.7969 5.09375 12.7969Z"
      stroke="black"
      strokeWidth="1.3125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
