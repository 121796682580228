import React, { useEffect, useState } from 'react';
import APIService from '../../../services/services';
import { DOUBLE_ARROW_DOWN, DOUBLE_ARROW_UP } from '../../../helpers/svgIcons';
const itemToShow = 5;

function Supplier({ destination, city, interest, state, language, metroRegion, zip, supplier, callback }: any) {
  const type = 'FacetedSuppliers';
  const [data, setData] = useState<any[]>([]);
  const [showFull, setShowFull] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [showAll, setShowAll] = useState(true);
  const fetch = () => {
    setLoading(true);
    APIService()
      .gtcService.fetchFaceted({
        destination: destination || '',
        agencyCity: city || '',
        interest: interest || '',
        state: state || '',
        language: language || '',
        metroRegion: metroRegion || '',
        zip: zip || '',
        supplier: supplier || '',
        type,
        radius: ''
      })
      .then((response) => {
        setLoading(false);
        if (response.responseStatus === 1) {
          setData(response.data);
        } else {
          setData([]);
        }
      });
  };

  useEffect(() => {
    fetch();
  }, [destination, interest, state, language, supplier, metroRegion, zip]);

  return (
    <>
      <div>
        <div className="my-4 flex flex-row justify-between items-center">
          <div className=" text-31-2">SUPPLIER PARTNER</div>
          <div className="cursor-pointer" onClick={() => setShowAll(!showAll)}>
            {showAll ? <DOUBLE_ARROW_UP /> : <DOUBLE_ARROW_DOWN />}
          </div>
        </div>
        {loading ? (
          <div className="flex animate-pulse flex-row  h-full justify-center">
            <div className=" bg-gtc4 h-44 w-full mr-4 mb-2"></div>
          </div>
        ) : (
          <div className={`${showAll ? 'max-h-[5000px]' : 'max-h-0'} transition-all delay-150 duration-300 overflow-hidden`}>
            {data.length > 0 ? (
              <div className="text-4xl flex flex-col">
                <div className="flex flex-col items-start">
                  <div className="flex flex-col gap-2.5 items-start ">
                    {data.slice(0, itemToShow).map((x) => (
                      <div
                        key={x.supplierName}
                        onClick={() => callback(x.supplierName)}
                        className={[
                          'text-base  cursor-pointer font-normal',
                          x.supplierName === supplier ? 'text-gray-400' : ' text-gtc1 '
                        ].join(' ')}
                        role="button"
                        tabIndex={0}>
                        {x.supplierName}
                      </div>
                    ))}
                    <div
                      className={`${
                        showFull ? 'max-h-[5000px]' : 'max-h-0'
                      } transition-all delay-150 duration-300 overflow-hidden w-full flex flex-col gap-2.5`}>
                      {data.slice(itemToShow, data.length).map((x) => (
                        <div
                          key={x.supplierName}
                          onClick={() => callback(x.supplierName)}
                          className={[
                            'text-base  cursor-pointer font-normal',
                            x.supplierName === supplier ? 'text-gray-400' : ' text-gtc1 '
                          ].join(' ')}
                          role="button"
                          tabIndex={0}>
                          {x.supplierName}
                        </div>
                      ))}
                    </div>
                  </div>
                  {data.length > 5 && (
                    <button
                      type="button"
                      className="text-20-8 w-full  cursor-pointer font-[350]   text-black  mt-2.5 "
                      onClick={() => setShowFull(!showFull)}>
                      {!showFull ? (
                        <div className="flex flex-row gap-1 items-center justify-between">
                          {/* <IoPlaySharp /> */}
                          More Suppliers
                          {` (${data.length - 5})`}
                          <DOUBLE_ARROW_DOWN />
                        </div>
                      ) : (
                        <div className="flex flex-row gap-1 items-center justify-between">
                          Close
                          <DOUBLE_ARROW_UP />
                        </div>
                      )}
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <div className="text-base">Please broaden your search criteria</div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default Supplier;
