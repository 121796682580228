import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import StarRatings from 'react-star-ratings';
import APIService from '../../services/services';
import { AdvertismentViewModel, AgentSearchBuildUrlParameters, AgentSearchParameters, AgentViewModel } from '../../helpers/types';
import Destination from '../layout/sidebar/Destination';
import Interest from '../layout/sidebar/Interest';
import State from '../layout/sidebar/State';
import Supplier from '../layout/sidebar/Supplier';
import Language from '../layout/sidebar/Language';
import queryString from 'query-string';
import { agentSearchFilterToParam, agentSearchQueryToFilter, callMe, getStateName } from '../../helpers/functions';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import React from 'react';
import DestinationExpert from '../../components/DestinationExpert';
import AgentSearchFooter from '../layout/AgentSearchFooter';
import Modal from 'react-modal';
import { modalCustomStyles } from '../../helpers/constants';
import EmailForm from '../layout/EmailForm';
import PhoneForm from '../layout/PhoneForm';
import AppointmentForm from '../layout/AppointmentForm';

import 'react-datepicker/dist/react-datepicker.css';
import { Helmet } from 'react-helmet';
import { Hero } from '../../components/Hero';
import MainWrapper from '../../components/MainWrapper';
import { CANCEL_ICON, FILTER_ICON, SEARCH_ICON, FILTER_ICON_BLACK } from '../../helpers/svgIcons';

interface Props {
  type?: string;
}

function Agents({ type }: Props) {
  const appState = useContext(AppContext);
  const filterRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const [agents, setAgents] = useState<AgentViewModel[]>([]);
  const [ads, setAds] = useState<AdvertismentViewModel[]>([]);
  const [totalAgents, setTotalAgents] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [agentName, setAgentName] = useState<string>('');
  const [expertise, SetExpertise] = useState<string>('');

  const navigate = useNavigate();

  const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      const offset = 80;
      const sectionPosition = ref.current.offsetTop - offset;

      window.scrollTo({
        top: sectionPosition,
        behavior: 'smooth'
      });
    }
  };

  const [filters, setFilters] = useState<AgentSearchParameters | undefined>();

  const [modalOpen, setModalOpen] = useState<'Email' | 'Phone' | 'Meet' | null>(null);

  const [showFilter, setShowFilter] = useState<boolean>(false);

  const [selectedAgent, setSelectedAgent] = useState<AgentViewModel | null>(null);
  const [title, setTitle] = useState<string>('');

  const parsed = queryString.parse(window.location.search);

  const getAgents = () => {
    if (filters) {
      if (type !== undefined && type === 'ITKE') {
        filters.agencyId = 643392;
      }
      setShowFilter(false);
      setLoading(true);
      APIService()
        .gtcService.fetchAgents(filters)
        .then((res) => {
          setLoading(false);
          if (res.responseStatus === 1) {
            setAds(res.data.ads);
            if (agents.length === 0 || filters?.currentPage === 1) setAgents(res.data.agents);
            else {
              setAgents([...agents.concat(res.data.agents)]);
            }
            setTotalAgents(res.data.totalAgents);
          } else {
            setAgents([]);
            setAds([]);
            setTotalAgents(0);
          }
        });
    }
  };

  const getMoreLinks = () => (
    <>
      {filters?.destination ||
      filters?.agentState ||
      filters?.agentMetro ||
      filters?.agentCity ||
      filters?.agentMetro ||
      filters?.interest ||
      filters?.agentSupplier ? (
        <div className="text-base h-screen">
          <p className=" text-base">
            We couldn't find any travel advisors who match your search criteria. But don't worry — there are still plenty of expert advisors
            ready to assist you in planning your dream getaway.
            <br />
            <br />
            Try simplifying your search by selecting one of the options below:
          </p>
          {filters?.destination ? (
            <div>
              <a
                className="text-orange hover:text-black font-bold"
                href={`/find-an-elite-travel-agent?slctDestination=${encodeURIComponent(filters?.destination)}`}>
                {`Travel agents who book travel to ${filters?.destination}`}
              </a>
            </div>
          ) : (
            ''
          )}
          {filters?.agentState ? (
            <div>
              <a className="text-orange hover:text-black font-bold" href={`/find-an-elite-travel-agent?slctState=${filters?.agentState}`}>
                {`Travel agents located in ${getStateName(filters?.agentState)}`}
              </a>
            </div>
          ) : (
            ''
          )}
          {filters?.agentMetro ? (
            <div>
              <a
                className="text-orange hover:text-black font-bold"
                href={`/find-an-elite-travel-agent?slctState=${filters?.agentState}&slctMetro=${encodeURIComponent(filters?.agentMetro)}`}>
                {`Travel agents located in ${filters?.agentMetro} Metro`}
              </a>
            </div>
          ) : (
            ''
          )}
          {filters?.agentCity ? (
            <div>
              <a
                className="text-orange hover:text-black font-bold"
                href={`/find-an-elite-travel-agent?slctState=${filters?.agentState}${
                  filters?.agentMetro.length > 0 ? `&slctMetro=${encodeURIComponent(filters?.agentMetro)}` : ''
                }&slctCity=${encodeURIComponent(filters?.agentCity)}`}>
                {`Travel agents located in ${filters?.agentCity}, ${filters?.agentState}`}
              </a>
            </div>
          ) : (
            ''
          )}
          {filters?.interest ? (
            <div>
              <a
                className="text-orange hover:text-black font-bold"
                href={`/find-an-elite-travel-agent?slctInterest=${encodeURIComponent(filters?.interest)}`}>
                {`${filters?.interest} Travel agents `}
              </a>
            </div>
          ) : (
            ''
          )}
          {filters?.agentSupplier ? (
            <div>
              <a
                className="text-orange hover:text-black font-bold"
                href={`/find-an-elite-travel-agent?slctSupplier=${encodeURIComponent(filters?.agentSupplier)}`}>
                {`Travel agents working with ${filters?.agentSupplier}`}
              </a>
            </div>
          ) : (
            ''
          )}
          <div>
            <a className="text-orange hover:text-black font-bold" href="/find-an-elite-travel-agent">
              Restart your search for the perfect travel agent.
            </a>
          </div>
        </div>
      ) : (
        <div>
          We couldn't find any travel advisors who match your search criteria. But don't worry — there are still plenty of expert advisors
          ready to assist you in planning your dream getaway.
        </div>
      )}
    </>
  );

  const getMoreMessage = () =>
    totalAgents === 0 && (
      <>
        {filters?.nearby === 'true' ? (
          <>
            {"- Sorry, it doesn't look like we have any agents near you. Please"}{' '}
            <span className="underline cursor-pointer font-bold" onClick={() => navigate(`/find-an-elite-travel-agent`)}>
              start a new search
            </span>{' '}
            {'to try to find the perfect agent for you'}
          </>
        ) : (
          <>
            {'- Please click'}{' '}
            <span className="underline cursor-pointer font-bold" onClick={() => navigate(`/find-an-elite-travel-agent?nearby=true`)}>
              here
            </span>{' '}
            {'for a list of advisors near you.'}
          </>
        )}
      </>
    );
  const constructTitle = () => {
    if (!filters) setTitle('Luxury Travel agents');
    else {
      var base = 'Luxury Travel agents';

      if (filters.agentState !== '' || filters.agentMetro !== '' || filters.agentCity !== '') {
        base = base.concat(' in');
      }

      var cityMetro = '';
      if (filters.agentMetro !== '' && filters.agentCity === '') {
        cityMetro = ` ${filters.agentMetro},`;
      }

      if (filters.agentCity !== '') {
        cityMetro = ` ${filters.agentCity},`;
      }
      base = base.concat(cityMetro);

      var state = '';
      if (filters.agentState !== '') {
        state = cityMetro === '' ? getStateName(filters.agentState) : filters.agentState;
        base = base.concat(' ' + state);
      }

      if (filters.agentName && filters.agentName !== '') {
        base = base.concat(` named ${filters.agentName}`);
      }
      if ((filters.interest && filters.interest !== '') || (filters.destination && filters.destination !== '')) {
        base = base.concat(` specializing in `);
      }
      if (filters.destination && filters.destination !== '') {
        base = base.concat(`${filters.destination}`);
      }
      if (filters.interest && filters.interest !== '') {
        base = base.concat(` ${filters.destination !== '' ? 'and' : ''} ${filters.interest}`);
      }
      if (filters.agentSupplier && filters.agentSupplier !== '') {
        base = base.concat(` who work with ${filters.agentSupplier}`);
      }
      if (filters.agentZip && filters.agentZip !== '') {
        base = base.concat(` located near ${filters.agentZip}`);
      }

      setTitle(base);
    }
  };

  const ad = (index: number) => {
    if (agents.length < 4 && ads.length > 0) {
      return index === agents.length ? (
        <a href={ads[0].adUrl + ads[0].adTracking} target="_blank" rel="noreferrer" className="cursor-pointer mx-4 col-span-2">
          <img className="mx-auto flex md:hidden" src={ads[0].mobileImageUrl} />
          <img className="mx-auto hidden md:flex" src={ads[0].imageUrl} />
        </a>
      ) : undefined;
    }

    const showAd = index % 4 === 0 || agents?.length < 4;
    if (showAd) {
      const key = index < 4 ? 0 : index / 4 - 1;
      return key < ads.length ? (
        <a href={ads[key].adUrl + ads[key].adTracking} target="_blank" rel="noreferrer" className="cursor-pointer mx-4 col-span-2">
          <img className="mx-auto flex md:hidden" src={ads[key].mobileImageUrl} />
          <img className="mx-auto hidden md:flex" src={ads[key].imageUrl} />
        </a>
      ) : undefined;
    }
    return undefined;
  };

  const agentCard = (agent: AgentViewModel, type: 'FirstRow' | 'SecondRow') => (
    <div className=" mx-auto flex flex-col justify-between bg-white  shadow-md overflow-hidden font-[Avenir] min-h-[275px]" key={agent.id}>
      <div>
        <div className="flex gap-4 items-center px-4 ">
          {/* <!-- Profile Picture --> */}
          <a href={agent.agentDetailsUrl}>
            <img
              src={agent.photoUrl}
              alt={constructAgentAlt(agent)}
              className="w-[139px]  h-[123px] object-cover object-top filter grayscale hover:grayscale-0 cursor-pointer"
            />
          </a>
          {/* <!-- Information --> */}
          <div className=" flex-1 flex flex-col gap-1">
            <a href={agent.agentDetailsUrl} className="cursor-pointer">
              <h3 className="text-31-2 uppercase text-left">{agent.fullName}</h3>
            </a>
            <div className="flex flex-col md:flex-row items-start md:items-center md:justify-between gap-1">
              {/* <!-- Star icons --> */}
              {agent.rating > 0 && (
                <StarRatings
                  rating={agent.rating}
                  starRatedColor="#606062"
                  // changeRating={this.changeRating}
                  numberOfStars={5}
                  starDimension="28"
                  starSpacing="2px"
                  name="rating"
                />
              )}

              {agent.reviewsCount > 0 && type === 'SecondRow' && (
                <span className="text-20-8 text-gtc1 font-[350]">({agent.reviewsCount} reviews)</span>
              )}
              {agent.reviewsCount > 0 && type === 'FirstRow' && (
                <span className="text-31-2 text-gtc1 font-[350]">({agent.reviewsCount})</span>
              )}
            </div>
            <div className="text-31-2 text-gtc1 text-left">{agent.location}</div>
          </div>
        </div>
        {/* <!-- Tags --> */}
        <div className="px-4">
          <p className="text-20-8 text-gtc1 text-left">{agent.interests.join(', ')}</p>
        </div>
      </div>
      {/* <!-- Button --> */}
      <div className="px-4 pb-2 flex">
        <a href={agent.agentDetailsUrl}>
          <button className="dark-btn hover:bg-white hover:text-black hover:border hover:border-black self-start w-[144px]">
            Learn More
          </button>
        </a>
      </div>
    </div>
  );

  const onCall = (agent: AgentViewModel) => {
    setSelectedAgent(agent);
    setModalOpen('Phone');
    callMe(agent);
  };

  let location = useLocation();
  useEffect(() => {
    constructTitle();
    if (filters) {
      getAgents();
    }
  }, [filters]);

  useEffect(() => {
    setAgentName('');
    SetExpertise('');
    Modal.setAppElement('#root');
    const parsed = queryString.parse(searchParams.toString());

    setFilters(agentSearchQueryToFilter(parsed as unknown as AgentSearchBuildUrlParameters, filters?.currentPage || 1));
  }, [searchParams]);

  const renderFilter = () => (
    <section className="my-8 flex flex-col gap-2 ">
      <div className="text-20-8">Where Would You Like To Go?</div>
      <div className="flex flex-col md:flex-row justify-evenly md:gap-10 gap-6 ">
        <div className="flex-1">
          <div className="relative  flex gap-3 w-full flex-wrap items-stretch">
            <div className="flex-1">
              <DestinationExpert
                callback={(dest: string) => {
                  if (filters) {
                    setFilters({ ...filters, destination: dest, currentPage: 1 });
                    navigate(`/find-an-elite-travel-agent?slctDestination=${encodeURIComponent(dest)}`);
                  }
                }}
              />
            </div>
            <button
              className="h-10 bg-black z-[2] flex justify-center items-center  hover:bg-gray-800 w-[70px]"
              type="button"
              id="button-search-destination">
              <SEARCH_ICON />
            </button>
          </div>
        </div>
        <div className="flex-1">
          <div className="relative flex gap-3 w-full flex-wrap items-stretch ">
            <input
              type="search"
              className="h-10 relative m-0  placeholder:text-gtc2   flex-auto 
                focus:bg-white border  border-gtc1 bg-transparent px-3 py-[0.25rem] text-base font-normal 
                 text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] 
                 focus:border-primary focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] 
                 focus:outline-none"
              placeholder="Search by Agent "
              aria-label="Search"
              value={agentName}
              onKeyDown={(event) => {
                if (filters && event.key === 'Enter') {
                  setFilters({ ...filters, agentName: agentName, currentPage: 1 });
                  navigate(`/find-an-elite-travel-agent?txtAgentName=${agentName}`);
                }
              }}
              onChange={(e: any) => {
                setAgentName(e.target.value);
              }}
            />

            <button
              className="h-10 bg-black z-[2] flex justify-center items-center  hover:bg-gray-800 w-[70px]"
              type="button"
              id="button-search-agent"
              onClick={() => {
                if (filters) {
                  setFilters({ ...filters, agentName: agentName, currentPage: 1 });
                  navigate(`/find-an-elite-travel-agent?txtAgentName=${agentName}`);
                }
                // navigate(0);
              }}>
              <SEARCH_ICON />
            </button>
          </div>
        </div>

        <div className="flex-1">
          <div className="relative flex gap-3 w-full flex-wrap items-stretch">
            <select
              className="h-10 relative m-0  block w-[1px] min-w-0 flex-auto  
              bg-gray-100 focus:bg-white border border-solid border-gtc1 bg-transparent bg-clip-padding px-3 py-[0.25rem] 
              text-base font-normal leading-[1.6]
             text-gtc2 outline-none transition 
             duration-200 ease-in-out focus:z-[3] focus:border-primary
              focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none
             placeholder:text-gtc2
             dark:focus:border-primary"
              placeholder="Interest"
              aria-label="Search Travel Interest Experts"
              onChange={(e: any) => {
                if (filters) {
                  setFilters({ ...filters, interest: encodeURIComponent(e.target.value), currentPage: 1 });
                  navigate(`/find-an-elite-travel-agent?slctInterest=${encodeURIComponent(e.target.value)}`);
                  SetExpertise(e.target.value);
                }
                // navigate(0);
              }}>
              <option value="">Interest</option>
              {appState.specialities.map((x) => (
                <option value={x.interest} key={x.interestId} selected={x.interest === expertise}>
                  {x.interest}
                </option>
              ))}
            </select>

            <button
              className="h-10 bg-black z-[2] flex justify-center items-center  hover:bg-gray-800 w-[70px]"
              type="button"
              id="button-search-interest">
              <SEARCH_ICON />
            </button>
          </div>
        </div>
      </div>
    </section>
  );

  const renderSideBar = () => (
    <div className="flex flex-col gap-1  w-full">
      <Destination
        destination={filters?.destination}
        interest={filters?.interest}
        city={filters?.agentCity}
        state={filters?.agentState}
        language={filters?.agentLanguage}
        metroRegion={filters?.agentMetro}
        zip={filters?.agentZip}
        supplier={filters?.agentSupplier}
        callback={(val: any) => {
          if (val === 0) {
            var url = queryString.exclude(location.search, ['slctDestination', 'nearby']);

            if (url.includes('?')) {
              url = url.replace('?', '');
            }
            goTo(url);
            if (filters) setFilters({ ...filters, destination: '', currentPage: 1 });
          } else {
            var params = queryString.parse(location.search);

            params = { ...params, slctDestination: val };
            let url = queryString.stringify(params);
            goTo(url);

            if (filters) setFilters({ ...filters, destination: val, currentPage: 1 });
          }

          //navigate(0);
        }}></Destination>
      <Interest
        destination={filters?.destination}
        interest={filters?.interest}
        city={filters?.agentCity}
        state={filters?.agentState}
        language={filters?.agentLanguage}
        metroRegion={filters?.agentMetro}
        zip={filters?.agentZip}
        supplier={filters?.agentSupplier}
        callback={(val: any) => {
          var params = queryString.parse(location.search);

          params = { ...params, slctInterest: val };
          let url = queryString.stringify(params);
          goTo(url);

          if (filters) setFilters({ ...filters, interest: val, currentPage: 1 });
        }}></Interest>

      <a href="/find-an-elite-travel-agent?slctState=UK">
        <div
          className="my-2 text-31-2 
         text-black  py-3 px-1 md:px-0 md:py-0">
          UK Agents
        </div>
      </a>

      <Supplier
        destination={filters?.destination}
        interest={filters?.interest}
        city={filters?.agentCity}
        state={filters?.agentState}
        language={filters?.agentLanguage}
        metroRegion={filters?.agentMetro}
        zip={filters?.agentZip}
        supplier={filters?.agentSupplier}
        callback={(val: any) => {
          var params = queryString.parse(location.search);

          params = { ...params, slctSupplier: val };
          let url = queryString.stringify(params);
          navigate(`/find-an-elite-travel-agent?${url}`);
          if (filters) setFilters({ ...filters, agentSupplier: val, currentPage: 1 });

          //  navigate(0);
        }}></Supplier>
      <Language
        destination={filters?.destination}
        interest={filters?.interest}
        city={filters?.agentCity}
        state={filters?.agentState}
        language={filters?.agentLanguage}
        metroRegion={filters?.agentMetro}
        zip={filters?.agentZip}
        supplier={filters?.agentSupplier}
        callback={(val: any) => {
          var params = queryString.parse(location.search);

          params = { ...params, slctLanguage: val };
          let url = queryString.stringify(params);
          navigate(`/find-an-elite-travel-agent?${url}`);

          if (filters) setFilters({ ...filters, agentLanguage: val, currentPage: 1 });

          //navigate(0);
        }}></Language>
      <State
        destination={filters?.destination}
        interest={filters?.interest}
        city={filters?.agentCity}
        state={filters?.agentState}
        language={filters?.agentLanguage}
        metroRegion={filters?.agentMetro}
        zip={filters?.agentZip}
        supplier={filters?.agentSupplier}
        callback={(type: any, val: any) => {
          if (type === 'state') {
            if (val === 0) {
              let url = queryString.exclude(location.search, ['slctState', 'slctMetro', 'slctCity']);

              if (url.includes('?')) {
                url = url.replace('?', '');
              }
              goTo(url);
              if (filters) setFilters({ ...filters, agentState: '', agentCity: '', agentMetro: '', currentPage: 1 });
            } else {
              let params = queryString.parse(location.search);

              params = {
                ...(delete params.slctCity && delete params.slctMetro && params),
                slctState: val
              };
              let url = queryString.stringify(params);
              goTo(url);
              if (filters) setFilters({ ...filters, agentState: val, currentPage: 1 });
            }
          } else if (type === 'metro') {
            if (val === 0) {
              let url = queryString.exclude(location.search, ['slctMetro', 'slctCity']);

              if (url.includes('?')) {
                url = url.replace('?', '');
              }
              goTo(url);
              if (filters) setFilters({ ...filters, agentCity: '', agentMetro: '', currentPage: 1 });
            } else {
              let params = queryString.parse(location.search);

              params = { ...(delete params.slctCity && params), slctMetro: val };
              console.log(params);
              let url = queryString.stringify(params);
              goTo(url);
              if (filters) setFilters({ ...filters, agentMetro: val, currentPage: 1 });
            }
          } else if (type === 'city') {
            if (val === 0) {
              let url = queryString.exclude(location.search, ['slctCity']);

              if (url.includes('?')) {
                url = url.replace('?', '');
              }
              goTo(url);
              if (filters) setFilters({ ...filters, agentCity: '', currentPage: 1 });
            } else {
              let params = queryString.parse(location.search);

              params = { ...params, slctCity: val };
              let url = queryString.stringify(params);
              goTo(url);
              if (filters) setFilters({ ...filters, agentCity: val, currentPage: 1 });
            }
          }
          //navigate(0);
        }}></State>
    </div>
  );

  const goTo = (url: string) => {
    var param = url.replace('?nearby=true&', '');
    param = param.replace('nearby=true&', '');
    param = param.replace('nearby=true', '');
    navigate(`/find-an-elite-travel-agent?${param.replace('?', '')}`);
  };

  const activeFilters = useMemo(() => {
    var selectedFilters: any[] = [];
    if (filters !== undefined) {
      const keys = Object.keys(filters);

      keys.forEach((key) => {
        if (
          filters[key as keyof AgentSearchParameters] !== '' &&
          !['agentId', 'useZipBiasing', 'currentPage', 'agencyId', 'nearby'].includes(key)
        )
          selectedFilters.push({
            param: agentSearchFilterToParam(key),
            key: key.replace('A', '1'),
            value: filters[key as keyof AgentSearchParameters]
          });
      });
    }

    selectedFilters = selectedFilters.reverse();

    if (selectedFilters.length === 0)
      return (
        <div className="flex flex-col gap-2 items-center mb-4 mt-2 md:mt-8  justify-center">
          <div className="uppercase text-20-8 text-center font-medium font-[Avenir]">Select criteria for best match</div>
          <button
            className={[
              'w-[41px] h-[41px] flex justify-center items-center md:hidden border border-black',
              showFilter ? ' bg-white' : 'bg-black'
            ].join(' ')}
            onClick={() => {
              if (!showFilter) scrollToSection(filterRef);
              setShowFilter(!showFilter);
            }}>
            {!showFilter ? <FILTER_ICON /> : <FILTER_ICON_BLACK />}
          </button>
        </div>
      );
    else {
      return (
        <div className="flex flex-col gap-6 items-center mb-4 mt-2 md:mt-8 justify-center">
          <div className="uppercase text-20-8 text-center font-medium font-[Avenir]">
            THERE ARE {totalAgents} ADVISORS MATCHING THIS SEARCH
          </div>
          <button
            className={[
              'w-[41px] h-[41px] flex justify-center items-center md:hidden border border-black',
              showFilter ? ' bg-white' : 'bg-black'
            ].join(' ')}
            onClick={() => {
              if (!showFilter) scrollToSection(filterRef);
              setShowFilter(!showFilter);
            }}>
            {!showFilter ? <FILTER_ICON /> : <FILTER_ICON_BLACK />}
          </button>
          <div className="flex flex-col md:flex-row items-center gap-6">
            {selectedFilters
              .filter((x) => x.key.toLowerCase() !== 'nearby' && x.key.toLowerCase() !== 'agencyid')
              .map((x) => {
                let filter = x.key;
                let value = x.value;

                if (filter.toLowerCase() === 'agentlanguage') {
                  filter = 'Language';
                }
                if (filter.toLowerCase() === 'agentstate') {
                  filter = 'Agent State';
                  value = getStateName(value);
                }
                if (filter.toLowerCase() === 'agentmetro') {
                  filter = 'Metro Region';
                }
                if (filter.toLowerCase() === 'agentcity') {
                  filter = 'Agent City';
                }

                if (filter.toLowerCase() === 'agentsupplier') {
                  filter = 'Supplier Partner';
                }

                return (
                  <React.Fragment key={x.key}>
                    {filter === 'Metro Region' && selectedFilters.some((x) => x.key.toLowerCase() === 'agentcity') ? (
                      <></>
                    ) : (
                      <div
                        className="border border-black px-3 py-1 flex flex-row gap-2 items-center w-fit
                      ">
                        <div>
                          <span className="capitalize text-20-8 font-[Avenir] font-medium">{filter}</span>: {value}
                        </div>
                        <div
                          className="-mt-4 -mr-6 cursor-pointer "
                          onClick={() => {
                            var url = queryString.exclude(
                              location.search,
                              x.param === 'slctState' ? [x.param, 'slctMetro', 'slctCity'] : [x.param]
                            );
                            goTo(url);
                            if (filters) {
                              x.param === 'slctState'
                                ? setFilters({
                                    ...filters,
                                    agentState: '',
                                    agentMetro: '',
                                    agentCity: '',
                                    currentPage: 1
                                  })
                                : setFilters({ ...filters, agentState: '', currentPage: 1 });
                            }

                            //navigate(0);
                          }}>
                          <CANCEL_ICON />
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
          </div>
        </div>
      );
    }
  }, [filters, showFilter, totalAgents]);

  const constructMetaTitle = () => {
    if (Array.from(searchParams).length === 0) return 'Find a Trusted Luxury Travel Agent | Global Travel Collection';
    else {
      return `${filters?.destination}  Travel Agents ${filters?.interest ? `for ${filters?.interest}` : ''} 
      ${filters?.agentState ? `in` : ''}
      ${filters?.agentMetro && !filters?.agentCity ? `${filters?.agentMetro},` : ''}
      ${filters?.agentCity ? ` ${filters?.agentCity},` : ''}
      ${filters?.agentState ? `${getStateName(filters?.agentState)}` : ''} | Page ${filters?.currentPage || 1} | Global Travel Collection`;
    }
  };
  const constructMetaDesc = () => {
    if (Array.from(searchParams).length === 0)
      return 'These trusted agents can provide you with personalized recommendations, detailed knowledge of destinations and exclusive deals & amenities for your next trip.';
    else {
      return `There are ${totalAgents} Luxury Agents in ${filters?.agentState} who specialize in 
      ${filters?.destination} ${filters?.interest} who can provide recommendations, knowledge and exclusive deals.`;
    }
  };
  const constructAgentAlt = (agent: AgentViewModel) => {
    if (Array.from(searchParams).length === 0) return `${agent.fullName} Luxury Travel Agent ${agent.city},${agent.state}`;
    else {
      return `Luxury Travel ${filters?.destination} ${filters?.interest} Agent ${agent.city},${agent.state}`;
    }
  };

  return (
    <>
      <Helmet>
        <meta name="title" content={constructMetaTitle()} />
        <meta name="description" content={constructMetaDesc()} />
        <title>{constructMetaTitle()}</title>
        {type === 'ITKE' && <link rel="stylesheet" href="/styles/itke.css" />}
      </Helmet>
      {type !== 'ITKE' && <Hero title="Find an Advisor" img="/images/new-hero/findadvisor.webp" />}

      <MainWrapper>
        {activeFilters}
        {renderFilter()}

        <div ref={filterRef}>
          {showFilter && <div className="absolute bg-white w-full z-10 left-0 right-0 px-4">{renderSideBar()}</div>}
        </div>
        {!showFilter && (
          <section className="flex flex-row gap-4 mx-0 mb-10">
            <div className="hidden md:flex min-w-[260px]">{renderSideBar()}</div>
            <div className="flex flex-col gap-3  text-center md:text-left" aria-label="Agent search" role="main">
              <div className="flex flex-col gap-10 mb-4  text-sm md:text-base">
                {loading && (
                  <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
                    <div className="loader ease-linear rounded-full border-4 border-t-4  h-12 w-12 mb-4"></div>
                    <div className="text-center text-white text-xl font-semibold">Searching...</div>
                  </div>
                )}
                {totalAgents === 0 && !loading && filters?.nearby !== 'true' && getMoreLinks()}
                {agents.length > 0 && (
                  <div
                    className="flex flex-col gap-6 p-4 md:p-8 object-bottom -mx-4 md:mx-0"
                    style={{
                      backgroundImage: "url('/images/new-hero/findadvisor.webp')",
                      boxShadow: 'inset 0 0 0 2000px rgba(0,0,0,0.4)',
                      backgroundPosition: 'bottom'
                    }}>
                    <div className="text-31-2 text-white uppercase">Expert advisors selected for you</div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8  md:mx-0">
                      {agents.slice(0, 2).map((agent: AgentViewModel, index: number) => {
                        return <React.Fragment key={agent.id}>{agentCard(agent, 'FirstRow')}</React.Fragment>;
                      })}
                    </div>
                  </div>
                )}
                <div className="md:grid  md:grid-cols-2 flex flex-col gap-8 -mx-4 md:mx-0">
                  {agents.slice(2, agents.length).map((agent: AgentViewModel, index: number) => {
                    return (
                      <React.Fragment key={agent.id}>
                        <div className="border border-gray-50">{agentCard(agent, 'SecondRow')}</div>
                        {ads.length > 0 && <>{ad(index + 1)}</>}
                      </React.Fragment>
                    );
                  })}
                </div>
                {agents.length > 0 && (
                  <>
                    {totalAgents > agents.length || (totalAgents > 20 && agents.length <= 20) ? (
                      <div className="flex justify-center">
                        <button
                          className="dark-btn  w-[150px]"
                          onClick={() =>
                            //@ts-ignore
                            setFilters({ ...filters, currentPage: filters.currentPage + 1 })
                          }>
                          VIEW MORE
                        </button>
                      </div>
                    ) : (
                      <div className="flex justify-center">
                        <button
                          className="dark-btn  w-[150px]"
                          onClick={() =>
                            //@ts-ignore
                            setFilters({ ...filters, currentPage: 1 })
                          }>
                          VIEW LESS
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </section>
        )}
      </MainWrapper>

      <Modal isOpen={modalOpen !== null} onRequestClose={() => setModalOpen(null)} style={modalCustomStyles}>
        <div className="z-50 ">
          <div className="flex justify-end cursor-pointer" onClick={() => setModalOpen(null)}>
            <i role="presentation" className="fa-solid fa-times text-black hover:text-gray-600"></i>
          </div>
          {selectedAgent && (
            <>
              {modalOpen === 'Meet' && <AppointmentForm agent={{ ...selectedAgent, agentAppointmentAvailabilty: [] }} />}
              {modalOpen === 'Phone' && <PhoneForm agent={selectedAgent} callback={() => setModalOpen('Email')} />}
              {modalOpen === 'Email' && <EmailForm agent={selectedAgent} />}
            </>
          )}
        </div>
      </Modal>
    </>
  );
}

export default Agents;
