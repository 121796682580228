/* eslint-disable no-unused-vars */
import { useRef, useState } from 'react';
import { AgentDetailViewModel, StoryItemViewModel } from '../../../helpers/types';
import 'react-image-gallery/styles/css/image-gallery.css';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import { SwiperButtonPrev } from '../../../components/SwiperButtonPrev';
import { SwiperButtonNext } from '../../../components/SwiperButtonNext';

interface GetInspiredProps {
  title: string;
  data: StoryItemViewModel[];
}

function GetInspired({ data, title }: GetInspiredProps) {
  const cardWidth = data.length < 3 ? 'w-full md:w-full' : 'w-full';
  const cardWrapper = () => {
    switch (data.length) {
      case 3:
        return 'md:px-0  md:mr-0';
      case 2:
        return '';
      case 1:
        return '';

      default:
        return 'md:px-14';
    }
  };
  return (
    <>
      <div className=" my-1 bg-white  text-black md:p-10 p-2 w-full mx-auto">
        <div className="text-lg md:text-3xl text-center mb-10">{title}</div>

        <div className={['relative md:mx-auto -mx-2', cardWrapper()].join(' ')}>
          <Swiper
            className="md:!px-4"
            spaceBetween={20}
            slidesPerView={1}
            loop={false}
            pagination={true}
            slidesPerGroupSkip={1}
            modules={[Navigation]}
            // navigation={{
            //   prevEl: '.button-prev',
            //   nextEl: '.button-next'
            // }}
            breakpoints={{
              400: {
                slidesPerView: 1,
                spaceBetween: 20,
                centeredSlides: false,
                centeredSlidesBounds: false
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 0,
                centeredSlides: false,
                centeredSlidesBounds: false
              },
              1024: {
                slidesPerView: 4,
                // spaceBetween: 20,
                centeredSlides: false,
                centeredSlidesBounds: false
              }
            }}>
            {data.length > 4 && (
              <>
                <div className="absolute inset-y-0 left-8 md:left-4 z-10 md:flex items-center top-40 md:top-0">
                  <SwiperButtonPrev className="" />
                </div>
                <div className="absolute inset-y-0 right-8 md:right-4 z-10 md:flex items-center top-40 md:top-0">
                  <SwiperButtonNext className="" />
                </div>
              </>
            )}
            <div className="px-20">
              {data.map((x, index) => (
                <>
                  <SwiperSlide key={x.title}>
                    <div className="swiper-slide md:px-3 ">
                      <a title={x.title} href={x.url} className="cursor-pointer">
                        <div className="w-full">
                          <img className={[cardWidth, ' object-cover w-full h-[187px]'].join(' ')} src={x.thumbnailImage} alt={x.title} />
                        </div>
                        <div className={[cardWidth, 'text-center bg-black min-h-[100px] pt-8 py-2 md:py-2 md:pt-0 flex '].join(' ')}>
                          <p className="text-20-8 text-left text-white uppercase font-normal font-[Avenir] px-3">{x.title}</p>
                        </div>
                      </a>
                    </div>
                  </SwiperSlide>
                </>
              ))}
            </div>
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default GetInspired;
